import React, { useEffect, useState } from 'react';
import loadable from '@loadable/component';

import {
  EventDispatcher,
  ON_MOBILE_MENU_OPEN_CHANGE,
} from '../../Shared/Common/EventDispatcher';

const MobileMenuContent = loadable(() => import('./MobileMenuContent'));

const onMobileOpenChange = (
  setIsOpen: any,
  setClosing: any,
  isOpen: boolean
) => {
  if (!isOpen) {
    setClosing(true);
    setTimeout(() => {
      setIsOpen(isOpen);
      setClosing(false);
    }, 300);
  } else {
    setIsOpen(isOpen);
  }
};

function MobileMenu() {
  const [isOpen, setIsOpen] = useState(false);
  const [closing, setClosing] = useState(false);

  useEffect(() => {
    const boundOnMobileChange = onMobileOpenChange.bind(
      null,
      setIsOpen,
      setClosing
    );

    EventDispatcher.subscribe(ON_MOBILE_MENU_OPEN_CHANGE, boundOnMobileChange);
    return () =>
      EventDispatcher.unsubscribe(
        ON_MOBILE_MENU_OPEN_CHANGE,
        boundOnMobileChange
      );
  }, []);

  return isOpen ? <MobileMenuContent closing={closing} /> : null;
}

export default MobileMenu;
